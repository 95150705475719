import styled from 'styled-components';
import { Theme } from '@pelando/components';
import { SearchSuggestionListItem } from '../../../SearchSuggestionListItem';

export const RemoveButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  margin-left: auto;

  i {
    color: rgb(${Theme.colors.Alpha});
    font-size: 24px;
  }
`;

export const Suggestion = styled(SearchSuggestionListItem)`
  & > i {
    color: rgb(${Theme.colors.Brand});
  }
`;
