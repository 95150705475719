import { BodyLight, ConditionalLink, Icon, Theme } from '@pelando/components';
import styled from 'styled-components';

export const SearchSuggestionListWrapper = styled.li`
  ${BodyLight}
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  color: rgb(${Theme.colors.Alpha});
  cursor: pointer;

  &:hover {
    background-color: rgb(${Theme.colors.Kilo});
    border-radius: 8px;
  }
`;

export const SearchSuggestionLeftIcon = styled(Icon)`
  font-size: 24px;
  margin-right: 8px;
`;

export const StyledConditionalLink = styled(ConditionalLink)`
  color: inherit;
`;
