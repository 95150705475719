import React, { LiHTMLAttributes } from 'react';
import { IconDefinition } from '@pelando/fontawesome';
import {
  SearchSuggestionListWrapper,
  SearchSuggestionLeftIcon,
  StyledConditionalLink,
} from './style';

export type SearchSuggestionListItemProps = LiHTMLAttributes<HTMLLIElement> & {
  children: React.ReactNode;
  iconName?: IconDefinition;
  url?: string;
};

export function SearchSuggestionListItem({
  iconName,
  children,
  url,
  ...rest
}: SearchSuggestionListItemProps) {
  return (
    <SearchSuggestionListWrapper role="option" tabIndex={0} {...rest}>
      <StyledConditionalLink url={url}>
        {iconName && <SearchSuggestionLeftIcon icon={iconName} />}
        {children}
      </StyledConditionalLink>
    </SearchSuggestionListWrapper>
  );
}
