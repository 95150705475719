import React, { HTMLAttributes } from 'react';
import { Icon, Searchbar } from '@pelando/components';
import { ArrowRight, MagnifyingGlass } from '@pelando/fontawesome/icons';
import { handleEnterKeyDown } from '../../helpers/handleKeydownEvent';

import { ListContainer, SectionTitle } from '../../styles';
import { SearchSuggestionType } from '../../types';

import { MostSearcheadTermItem } from './style';

export type MostSearchedTermsProps = {
  terms?: string[];
  title: string;
} & HTMLAttributes<HTMLUListElement>;

export const MostSearchedTerms = ({
  terms,
  title,
  ...htmlProps
}: MostSearchedTermsProps) => {
  if (!terms?.length) return null;

  return (
    <ListContainer role="listbox" id="most-searched-terms-list" {...htmlProps}>
      <SectionTitle>{title}</SectionTitle>
      {terms.map((word) => (
        <Searchbar.SuggestionItem
          key={`most_searched_${word}`}
          value={{
            type: SearchSuggestionType.MOST_SEARCHED,
            word,
          }}
          renderSuggestion={({ onSelectSuggestion }) => (
            <MostSearcheadTermItem
              key={`frequent_${Math.random()}`}
              onClick={onSelectSuggestion}
              onKeyDown={(event) =>
                handleEnterKeyDown(event, onSelectSuggestion)
              }
              tabIndex={0}
              iconName={MagnifyingGlass}
            >
              <span>
                {word}
                <Icon icon={ArrowRight} />
              </span>
            </MostSearcheadTermItem>
          )}
        />
      ))}
    </ListContainer>
  );
};
