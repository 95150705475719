import React, { HTMLAttributes } from 'react';
import { Searchbar } from '@pelando/components';

import { RecentSearchItem } from './components/RecentsSearchItem';
import { ListContainer, SectionTitle } from '../../styles';
import { SearchSuggestionType } from '../../types';
import { handleEnterKeyDown } from '../../helpers/handleKeydownEvent';

export type RecentsSearchesProps = {
  recents?: string[];
  title: string;
} & HTMLAttributes<HTMLUListElement>;

export const RecentsSearches = ({
  recents,
  title,
  ...htmlProps
}: RecentsSearchesProps) => {
  if (!recents?.length) return null;

  return (
    <ListContainer role="listbox" id="recents-list" {...htmlProps}>
      <SectionTitle>{title}</SectionTitle>
      {recents.map((word) => (
        <Searchbar.SuggestionItem
          key={`recent-${word}`}
          value={{
            word,
            type: SearchSuggestionType.RECENTS,
          }}
          renderSuggestion={({ onSelectSuggestion, onRemoveSuggestion }) => (
            <RecentSearchItem
              onClick={onSelectSuggestion}
              onRemove={onRemoveSuggestion}
              onKeyDown={(event) =>
                handleEnterKeyDown(event, onSelectSuggestion)
              }
            >
              {word}
            </RecentSearchItem>
          )}
        />
      ))}
    </ListContainer>
  );
};
