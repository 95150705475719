import { SearchCommands, Searchbar } from '@pelando/components';
import { RefObject, useEffect } from 'react';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { SearchBarRoot, SuggestionsBox } from './styles';
import { RecentsSearches } from './components/RecentsSearches';
import { MostSearchedTerms } from './components/MostSearchedTerms';
import { SearchedProducts } from './components/SearchedProducts';
import { AutoCompleteSuggestion, ProductSuggestion } from './types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { AutocompleteSuggestions } from './components/AutocompleteSuggestions';

export type SearchbarProps = {
  opened: boolean;
  recents: string[];
  frequentSearches: string[];
  products: ProductSuggestion[];
  suggestions: AutoCompleteSuggestion[];
  searchRef?: RefObject<HTMLDivElement>;
  defaultValue?: string;
  inputRef?: RefObject<HTMLInputElement>;
  isInputOpen: boolean;
  commands: SearchCommands;
};

type HeaderSearchBarProps = {
  isLoading?: boolean;
} & SearchbarProps;

export const HeaderSearchBar = ({
  commands,
  frequentSearches,
  recents,
  products,
  suggestions,
  isInputOpen,
  defaultValue,
  opened,
  inputRef,
  searchRef,
}: HeaderSearchBarProps) => {
  const { t } = useTranslation('search');

  useEffect(() => {
    if (!opened) return;
    (searchRef?.current?.children[0] as HTMLElement)?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <>
      <SearchBarRoot
        ref={searchRef}
        commands={commands}
        defaultValue={defaultValue}
        inputRef={inputRef}
        isSearchBarDropdownOpen={opened}
        isInputOpen={isInputOpen}
        data-opened={opened}
      >
        <Searchbar.SearchBox
          inputLabel={t('searchbar-searchbox-input-label')}
          inputPlaceholder={t('searchbar-searchbox-placeholder')}
          backButtonLabel={t('searchbar-searchbox-back-button-label')}
        />
        <SuggestionsBox>
          <AutocompleteSuggestions
            suggestions={suggestions}
            currentSearchValue={defaultValue}
          />
          <RecentsSearches
            title={t('searchbar-suggestionsbox-recents-title')}
            recents={recents}
          />
          <MostSearchedTerms
            title={t('searchbar-suggestionsbox-most-searched-title')}
            terms={frequentSearches}
          />
          <SearchedProducts
            title={t('products-suggestion-title')}
            products={products}
          />
        </SuggestionsBox>
      </SearchBarRoot>
      <LockScrollGlobally lock={opened} />
    </>
  );
};
