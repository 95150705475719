import React, { HTMLAttributes } from 'react';
import { Icon, Searchbar } from '@pelando/components';
import { ArrowRight, MagnifyingGlass } from '@pelando/fontawesome/icons';
import { handleEnterKeyDown } from '../../helpers/handleKeydownEvent';
import { AutocompleteSuggestion } from './styles';
import { ListContainer, SectionTitle } from '../../styles';
import { AutoCompleteSuggestion, SearchSuggestionType } from '../../types';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { getHighlightedTextBySearch } from '../../../../../../services/strings/getHighlightedTextBySearch';

export type AutocompleteSuggestionsProps = {
  suggestions?: AutoCompleteSuggestion[];
  currentSearchValue?: string;
} & HTMLAttributes<HTMLUListElement>;

export const AutocompleteSuggestions = ({
  suggestions,
  currentSearchValue,
  ...htmlProps
}: AutocompleteSuggestionsProps) => {
  const { t } = useTranslation('search');

  if (!suggestions?.length || !currentSearchValue) return null;

  return (
    <ListContainer
      role="listbox"
      id="autocomplete-suggestions-list"
      data-testid="autocomplete-suggestions-list"
      {...htmlProps}
    >
      <SectionTitle>
        {t('searchbar-autocomplete-suggestions-title')}
      </SectionTitle>
      {suggestions.map(({ url, suggestion }) => (
        <Searchbar.SuggestionItem
          value={{
            suggestion,
            url,
            type: SearchSuggestionType.AUTOCOMPLETE,
          }}
          key={`autocomplete-suggestion-${suggestion}`}
          renderSuggestion={({ onSelectSuggestion }) => (
            <AutocompleteSuggestion
              onClick={onSelectSuggestion}
              onKeyDown={(event) =>
                handleEnterKeyDown(event, onSelectSuggestion)
              }
              tabIndex={0}
              iconName={MagnifyingGlass}
              url={url}
            >
              <span>
                <div>
                  {getHighlightedTextBySearch(suggestion, currentSearchValue)}
                </div>
                <Icon icon={ArrowRight} />
              </span>
            </AutocompleteSuggestion>
          )}
        />
      ))}
    </ListContainer>
  );
};
