import React, { HTMLAttributes } from 'react';
import { Searchbar, SmallProductCard } from '@pelando/components';

import { handleEnterKeyDown } from '../../helpers/handleKeydownEvent';

import { ListOption } from './styles';
import { ListContainer, SectionTitle } from '../../styles';
import { ProductSuggestion, SearchSuggestionType } from '../../types';

export type SearchedProductsProps = {
  products?: ProductSuggestion[];
  title: string;
} & HTMLAttributes<HTMLUListElement>;

export const SearchedProducts = ({
  products,
  title,
  ...htmlProps
}: SearchedProductsProps) => {
  if (!products?.length) return null;

  return (
    <ListContainer
      role="listbox"
      id="most-searched-products-list"
      {...htmlProps}
    >
      <SectionTitle>{title}</SectionTitle>
      {products.map(({ id, name, url, image }) => (
        <Searchbar.SuggestionItem
          value={{
            id,
            type: SearchSuggestionType.PRODUCTS,
          }}
          key={`product_${id}`}
          renderSuggestion={({ onSelectSuggestion }) => (
            <ListOption role="option">
              <SmallProductCard
                tabIndex={0}
                title={name}
                url={url}
                productImage={image}
                onClick={onSelectSuggestion}
                onKeyDown={(event) =>
                  handleEnterKeyDown(event, () => event.currentTarget.click())
                }
              />
            </ListOption>
          )}
        />
      ))}
    </ListContainer>
  );
};
