import React from 'react';
import { Icon } from '@pelando/components';

import { ClockRotateLeft, Xmark } from '@pelando/fontawesome/icons';
import { SearchSuggestionListItemProps } from '../../../SearchSuggestionListItem';
import { RemoveButton, Suggestion } from './styles';

type RecentSearchItemProps = Omit<SearchSuggestionListItemProps, 'iconName'> & {
  onRemove: () => void;
  removeLabel?: string;
};

export const RecentSearchItem = ({
  onRemove,
  children,
  removeLabel,
  ...suggestionProps
}: RecentSearchItemProps) => (
  <Suggestion {...suggestionProps} iconName={ClockRotateLeft}>
    {children}
    <RemoveButton
      type="button"
      onKeyDown={(event) => {
        if (event.key !== 'Enter') return;
        event.stopPropagation();
        event.preventDefault();
        onRemove();
      }}
      onClick={(event) => {
        event.stopPropagation();
        onRemove();
      }}
      title={removeLabel}
    >
      <Icon icon={Xmark} />
    </RemoveButton>
  </Suggestion>
);
