import styled, { css } from 'styled-components';
import {
  MediaQuery,
  Searchbar,
  Skeleton,
  BodyHeavy,
  Theme,
} from '@pelando/components';

const searchbarStyles = css`
  grid-area: search-bar;
  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 0 auto;
    width: 100%;
    max-width: 584px;
  }
`;

export const SearchBarRoot = styled(Searchbar.Root)`
  ${searchbarStyles}
`;

export const SearchbarSkeleton = styled(Skeleton)`
  ${searchbarStyles}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto;

  @media ${MediaQuery.SCREEN_MD_UP} {
    border-radius: 24px;
    margin: 0 auto;
    width: 100%;
  }
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 8px;

  &:first-of-type {
    margin-top: unset;
  }
`;

export const SectionTitle = styled.p`
  ${BodyHeavy}
  color: rgb(${Theme.colors.Alpha});
  margin-bottom: 16px;

  &:first-of-type {
    margin-top: unset;
  }
`;

export const SuggestionsBox = styled(Searchbar.Suggestions)`
  @media ${MediaQuery.SCREEN_MD_UP} {
    &[open] {
      max-height: 788px;
      min-width: 320px;
    }
  }
`;
