export const getHighlightedTextBySearch = (text: string, search?: string) => {
  if (!search) return text;
  const lowerCaseSearch = search.toLowerCase();
  const lowerCaseText = text.toLowerCase();
  const searchLength = search.length;

  const index = lowerCaseText.indexOf(lowerCaseSearch);

  const isTextMatch = index !== -1;

  if (!isTextMatch) return text;

  const beforeMatch = text.slice(0, index + searchLength);
  const afterMatch = text.slice(index + searchLength);

  return (
    <>
      {beforeMatch}
      <strong style={{ whiteSpace: 'pre-wrap' }}>
        {beforeMatch.endsWith(' ') ? ' ' : undefined}
        {afterMatch}
      </strong>
    </>
  );
};
