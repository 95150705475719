import styled from 'styled-components';
import { Colors, Theme } from '@pelando/components';
import { SearchSuggestionListItem } from '../SearchSuggestionListItem';

export const AutocompleteSuggestion = styled(SearchSuggestionListItem)`
  span {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    column-gap: 8px;

    i:last-of-type {
      font-size: 24px;
      color: rgb(${Theme.colors.Brand});
    }
  }

  i:first-of-type {
    color: rgb(${Colors.Gray});
  }
`;
